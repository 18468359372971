import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Container, Row, Col, Card, Form, InputGroup, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom'; // Assuming you're using React Router for navigation
import './style.css';

import logo from './logo 2.png';
import lock from './lock.png';
import user from './user.png';
import { API_URL } from '../../utils/util';
import { IUser } from '../../interfaces/IUser';
import { useDispatch } from 'react-redux';
import { setInitialState, setUser } from '../../store/slice/authSlice';
import { client } from '../../utils/client';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setInitialState());
  }, [dispatch]);

  async function login(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formBody = { username, password };
    try {
      const response = await client({
        url: 'verify-profile',
        data: formBody,
        method: 'POST'
      });
      if (response.status === 200) {
        const { data } = response;
        const { user }: { user: IUser } = data;
        if (data.status === 'success') {
          dispatch(setUser(user));
          localStorage.setItem('user_info', JSON.stringify(user));
          // toast.success('You are now logged in', { autoClose: 2000 });
          history.push(`/video${location.search}`);
        } else {
          toast.error(data.message, { autoClose: 2000 });
        }
      } else {
        console.error('HTTP Error:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Axios Error:', error);
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="card-group">
              <div className="card">
                <div className="card overflow-hidden account-card ">
                  <div className="p-4 text-white text-center position-relative" style={{ background: 'rgb(0, 0, 0)' }}>
                    <p className="mb-4" style={{ fontSize: '14px' }}>
                      Sign in to continue to Ram Book Exchange.
                    </p>
                  </div>
                  <a href="/" className="logo" style={{ textDecoration: 'none' }}>
                    {/* <h5 style={{ color: 'white' }}>Ram Book Exchange</h5> */}
                    <img src={logo} alt="Logo" />
                  </a>
                </div>
                <div className="card-body p-4 card login_card">
                  <form style={{ textAlign: 'left' }} onSubmit={login}>
                    <label htmlFor="username" style={{ marginBottom: '7px' }}>
                      Username
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <img src={user} alt="Logo" />
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        autoComplete="username"
                      />
                    </div>
                    <label htmlFor="password" style={{ marginBottom: '7px' }}>
                      Password
                    </label>
                    <div className="input-group mb-4">
                      <span className="input-group-text">
                        <img src={lock} alt="Logo" />
                      </span>
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="text-center">
                          <button className="btn btn-primary px-4" type="submit">
                            Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="m-t-40 text-center" style={{ paddingBottom: '5px' }}>
                  <p style={{ fontSize: '14px', padding: '0px 0px 25px 0px' }}>
                    © 2024 Ram Book Exchange Pvt Ltd. Crafted with ❤
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
