import Pusher, { Channel } from 'pusher-js';
import { isDev } from './util';

export const pusherObj = {
  channel: isDev() ? 'my-channel2' : 'my-channel',
  event: isDev() ? 'my-events' : 'my-event'
};

export class PusherClient {
  public static instance: PusherClient;

  public static getInstance(): PusherClient {
    if (!PusherClient.instance) {
      PusherClient.instance = new PusherClient();
    }
    return PusherClient.instance;
  }

  public pusher: Pusher;
  public channel: Channel;
  private constructor() {
    Pusher.logToConsole = isDev();
    this.pusher = new Pusher('08cb62f48ae8fc8c20ad', {
      cluster: 'ap2'
    });
    this.channel = this.pusher.subscribe(pusherObj.channel);
  }
}
