import { useCallback, useContext, useEffect, useState } from 'react';
import zoomContext from '../../../context/zoom-context';
import ZoomMediaContext from '../../../context/media-context';
import { AudioChangeAction, MutedSource } from '@zoom/videosdk';
import { message } from 'antd';
import { useMicAction } from '../hooks/useMicAction';
import './tap-sounds.scss';

export const Mic = () => {
  const zmClient = useContext(zoomContext);
  const { mediaStream } = useContext(ZoomMediaContext);

  const [isStartedAudio, setIsStartedAudio] = useState(
    zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== ''
  );
  useMicAction(isStartedAudio);
  const [isMuted, setIsMuted] = useState(!!zmClient.getCurrentUserInfo()?.muted);
  const [isMicrophoneForbidden, setIsMicrophoneForbidden] = useState(false);
  const [audio, setAudio] = useState(zmClient.getCurrentUserInfo()?.audio);

  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio && mediaStream) {
      const isHost = zmClient.isHost();
      if (isHost) {
        setIsMuted(!isMuted);
        if (isMuted) {
          await mediaStream.unmuteAudio();
        } else {
          await mediaStream.muteAudio();
        }
      } else {
        if (mediaStream) {
          setIsMuted(false);
          await mediaStream.unmuteAudio();
        }
      }
    } else {
      try {
        await mediaStream?.startAudio();
        await mediaStream?.muteAudio();
        setIsStartedAudio(true);
      } catch (e: any) {
        if (e.type === 'INSUFFICIENT_PRIVILEGES' && e.reason === 'USER_FORBIDDEN_MICROPHONE') {
          setIsMicrophoneForbidden(true);
          message.info('Microphone is forbidden, please give permission from the setting');
        }
        console.warn(e);
      }
    }
  }, [isStartedAudio, mediaStream, zmClient, isMuted]);

  const onHostAudioMuted = useCallback(
    (payload: any) => {
      const { action, source, type } = payload;
      if (action === AudioChangeAction.Join) {
        setIsStartedAudio(true);
        setAudio(type);
        setTimeout(() => {
          setIsMuted(!!zmClient.getCurrentUserInfo()?.muted);
        }, 1000);
      } else if (action === AudioChangeAction.Leave) {
        setIsStartedAudio(false);
      } else if (action === AudioChangeAction.Muted) {
        setIsMuted(true);
        if (source === MutedSource.PassiveByMuteOne) {
          message.info('Host muted you');
        }
      } else if (action === AudioChangeAction.Unmuted) {
        setIsMuted(false);
        if (source === 'passive') {
          message.info('Host unmuted you');
        }
      }
    },
    [zmClient]
  );

  useEffect(() => {
    zmClient.on('current-audio-change', onHostAudioMuted);
    return () => {
      zmClient.off('current-audio-change', onHostAudioMuted);
    };
  });

  let micClass = ['imgmicen', 'imgmicoff', 'imgmicon', 'imgmicforb'];
  let microPhoneClass;

  if (!isStartedAudio) {
    microPhoneClass = micClass.at(0);
  } else if (isMicrophoneForbidden) {
    microPhoneClass = micClass.at(3);
  } else if (isMuted) {
    microPhoneClass = isMuted ? micClass.at(1) : micClass.at(0);
  } else {
    microPhoneClass = micClass.at(2);
  }

  const isHost = zmClient.isHost();
  return (
    <div className="tap-2">
      <div className="btn-2 btn-speak">
        <button
          className="button-tap"
          onClick={() => {
            if (isHost) {
              onMicrophoneClick();
            }
          }}
          onMouseDown={() => {
            if (!isHost) {
              onMicrophoneClick();
            }
          }}
          onTouchStart={() => {
            if (!isHost) {
              onMicrophoneClick();
            }
          }}
        >
          <div className={microPhoneClass}> </div>
          {/* <img style={{ width: '45px' }} src={microPhoneIcon} alt="" /> */}
        </button>
        {!isHost && <span>Tap and hold to speak</span>}
      </div>
    </div>
  );
};
