import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import zoomContext from '../../context/zoom-context';
import { MediaStream } from '../../index-types';
import ZoomMediaContext from '../../context/media-context';
import Video from '../../feature/video/video';
import VideoSingle from '../../feature/video/video-single';
import VideoAttach from '../../feature/video/video-attach';
import { devConfig } from '../../config/dev';
import produce from 'immer';
import { message } from 'antd';
import ZoomVideo from '@zoom/videosdk';
import LoadingLayer from '../../component/loading-layer';
import { b64DecodeUnicode, generateVideoToken } from '../../utils/util';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { usePusher } from '../../hooks/usePusher';

const meetingArgs: any = { ...devConfig };

interface AppProps {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    enforceVB?: string;
    customerJoinId?: string;
    lang?: string;
    useVideoPlayer?: string;
  };
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

['enforceGalleryView', 'enforceVB', 'cloud_recording_option', 'cloud_recording_election'].forEach((field) => {
  if (Object.hasOwn(meetingArgs, field)) {
    try {
      meetingArgs[field] = Number(meetingArgs[field]);
    } catch (e) {
      meetingArgs[field] = 0;
    }
  }
});

if (meetingArgs?.telemetry_tracking_id) {
  try {
    meetingArgs.telemetry_tracking_id = b64DecodeUnicode(meetingArgs.telemetry_tracking_id);
  } catch (e) {}
} else {
  meetingArgs.telemetry_tracking_id = '';
}

const VideoInitMiddelware = (props: AppProps) => {
  const {
    sdkKey,
    topic,
    password,
    signature,
    webEndpoint: webEndpointArg,
    enforceGalleryView,
    enforceVB,
    customerJoinId,
    lang,
    useVideoPlayer
  } = meetingArgs as AppProps['meetingArgs'];

  const history = useHistory();

  let username = '';
  let role = 0;

  const [userInfo] = usePusher();
  const adminAction = useSelector((state: RootState) => state.auth.adminAction);
  if (userInfo) {
    const userInfoObject = userInfo;
    ({ username, role } = userInfoObject);
  }
  const [loading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');

  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);

  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(false);
  let webEndpoint: any;
  if (webEndpointArg) {
    webEndpoint = webEndpointArg;
  } else {
    webEndpoint = window?.webEndpoint ?? 'zoom.us';
  }

  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;
  const galleryViewWithAttach = Number(useVideoPlayer) === 1 && (window.crossOriginIsolated || galleryViewWithoutSAB);
  const zmClient = useContext(zoomContext);

  useEffect(() => {
    setLoadingText('Joining the session...' + username);
  }, [username]);

  useEffect(() => {
    const init = async () => {
      if (adminAction === 'ACCEPTED' || userInfo?.id === 0) {
        if (!meetingArgs.signature && meetingArgs.sdkSecret && meetingArgs.topic) {
          console.log('role', role);
          meetingArgs.signature = generateVideoToken(
            meetingArgs.sdkKey,
            meetingArgs.sdkSecret,
            meetingArgs.topic,
            meetingArgs.sessionKey,
            meetingArgs.userIdentity,
            Number(role ?? 1),
            meetingArgs.cloud_recording_option,
            meetingArgs.cloud_recording_election,
            meetingArgs.telemetry_tracking_id
          );
          console.log('=====================================');
          console.log('meetingArgs', meetingArgs);

          const urlArgs: any = {
            topic: meetingArgs.topic,
            name: meetingArgs.name,
            password: meetingArgs.password,
            sessionKey: meetingArgs.sessionKey,
            userIdentity: meetingArgs.userIdentity,
            role: role,
            cloud_recording_option: meetingArgs.cloud_recording_option || '',
            cloud_recording_election: meetingArgs.cloud_recording_election || '',
            telemetry_tracking_id: meetingArgs.telemetry_tracking_id || '',
            enforceGalleryView: 0,
            enforceVB: 0,
            web: '1'
          };
          console.log('use url args');
          console.log(window.location.origin + '/?' + new URLSearchParams(urlArgs).toString());
        }
        await zmClient.init('en-US', `${window.location.origin}/lib`, {
          webEndpoint,
          enforceMultipleVideos: galleryViewWithoutSAB,
          enforceVirtualBackground: vbWithoutSAB,
          stayAwake: true,
          patchJsMedia: true,
          leaveOnPageUnload: false
        });
        try {
          if (username && password) {
            console.log('joining name', topic, meetingArgs.signature, username, password);
            await zmClient.join(topic, meetingArgs.signature, username, password);
            const stream = zmClient.getMediaStream();
            (window as any).stream = stream;
            (window as any).zmClient = zmClient;
            console.log('stream.isSupportMultipleVideos()', stream.isSupportMultipleVideos());
            setMediaStream(stream);
            setIsSupportGalleryView(stream.isSupportMultipleVideos());
            setIsLoading(false);
          } else {
            history.replace('/');
          }
        } catch (e: any) {
          setIsLoading(false);
          message.error(e.reason);
        }
      } else if (adminAction === 'REJECTED') {
        history.replace('/');
      }
    };
    init();
    return () => {
      ZoomVideo.destroyClient();
    };
  }, [
    adminAction,
    galleryViewWithoutSAB,
    history,
    password,
    role,
    topic,
    userInfo?.id,
    username,
    vbWithoutSAB,
    webEndpoint,
    zmClient
  ]);

  return (
    <>
      {loading && <LoadingLayer content={loadingText} userId={userInfo?.id} />}
      {!loading && (
        <ZoomMediaContext.Provider value={mediaContext}>
          <Video />
        </ZoomMediaContext.Provider>
      )}
    </>
  );
};

export default VideoInitMiddelware;
