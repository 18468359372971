import { useEffect, useState, useCallback, useRef, useContext } from 'react';
import ZoomContext from '../../../context/zoom-context';

export interface ISpeakingUser {
  userId: number;
  displayName: string;
}

export function useSpeakingUsers() {
  const zmClient = useContext(ZoomContext);
  const [speakingUsers, setSpeakingUsers] = useState<ISpeakingUser[]>([]);
  const onActiveSpeakerChange = useCallback(
    (payload: any) => {
      if (Array.isArray(payload) && payload.length > 0) {
        const speakingUsers = payload.filter(
          (item: { userId: number; displayName: string }) => item.userId !== zmClient.getSessionInfo().userId
        );
        setSpeakingUsers(speakingUsers);
      }
    },
    [zmClient]
  );

  useEffect(() => {
    let activeSpeakerTimer = setTimeout(() => {
      setSpeakingUsers([]);
    }, 3000);
    return () => {
      clearTimeout(activeSpeakerTimer);
    };
  }, [speakingUsers]);
  useEffect(() => {
    zmClient.on('active-speaker', onActiveSpeakerChange);
    return () => {
      zmClient.off('active-speaker', onActiveSpeakerChange);
    };
  }, [zmClient, onActiveSpeakerChange]);
  return speakingUsers;
}
