import axios from 'axios';
import { API_URL } from './util';

export interface IResponse {
  status: string;
  error: string | number;
  result: any;
}

export const client = axios.create({
  baseURL: `${API_URL}`,
  // timeout: 1000,
  headers: { Accept: 'application/json' }
});
