import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { PusherClient, pusherObj } from '../utils/pusher';
import zoomContext from '../context/zoom-context';
import { IUser } from '../interfaces/IUser';
import { IAuth, addUserInvitation, removeUserFromInvitation, setAction } from '../store/slice/authSlice';
import { toast } from 'react-toastify';
import { client } from '../utils/client';

export const usePusher = () => {
  const userInfo = useSelector((state: RootState) => state.auth.user);
  const zmClient = useContext(zoomContext);
  const dispatch = useDispatch();
  const isHost = zmClient.isHost();
  useEffect(() => {
    const pusher = PusherClient.getInstance();
    if (userInfo) {
      client({
        url: 'clear-invitation',
        method: 'POST',
        data: {
          userId: userInfo.id
        }
      });
      const myEvent = `${pusherObj.event}${userInfo.id}`;
      pusher.channel
        .unbind_all()
        .bind(`${myEvent}`, (payload: { user: IUser; to: number; action: IAuth['adminAction'] }) => {
          if (isHost && payload.to === 0) {
            if (payload.user && payload.action === 'ADD_USER_INVITATION') {
              dispatch(addUserInvitation(payload.user));
            } else if (payload.action === 'CLEAR_INVITATION') {
              dispatch(removeUserFromInvitation(payload.user.id));
            }
          } else {
            if (payload.action === 'REJECTED') {
              toast.error('you are not allowed to join the meeting, pleas try again');
            }
            dispatch(setAction(payload.action));
          }
        });
    }
    return () => {
      pusher.channel.unbind_all();
    };
  }, [dispatch, isHost, userInfo]);
  return [userInfo];
};
