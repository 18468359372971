import { FC, useCallback, useContext, useEffect, useState } from 'react';
import ZoomMediaContext from '../../../context/media-context';
import ZoomContext from '../../../context/zoom-context';

export const useMicAction = (isStartedAudio: boolean) => {
  const zmClient = useContext(ZoomContext);
  const { mediaStream } = useContext(ZoomMediaContext);
  const [isStartedAudioState, setIStartAudioState] = useState(false);
  const isHost = zmClient.isHost();
  const onMouseUp = useCallback(async () => {
    if (!isHost && isStartedAudioState && mediaStream && !mediaStream.isAudioMuted()) {
      await mediaStream.muteAudio();
    }
  }, [isHost, isStartedAudioState, mediaStream]);

  const onTouchEnd = useCallback(async () => {
    if (!isHost && isStartedAudioState && mediaStream && !mediaStream.isAudioMuted()) {
      await mediaStream.muteAudio();
    }
  }, [isHost, isStartedAudioState, mediaStream]);
  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('touchend', onTouchEnd);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, [onMouseUp, onTouchEnd]);

  useEffect(() => {
    setIStartAudioState(isStartedAudio);
  }, [isStartedAudio]);
};
