import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../interfaces/IUser';
import { client, IResponse } from '../../utils/client';

const userInfo = localStorage.getItem('user_info');
let userInfoObject: IUser | null = null;
if (userInfo) {
  userInfoObject = JSON.parse(userInfo);
}
export interface IAuth {
  user: IUser | null;
  userInvitations: IUser[];
  adminAction: 'ADD_USER_INVITATION' | 'CLEAR_INVITATION' | 'ACCEPTED' | 'REJECTED' | '';
  loading: boolean;
  loadingText: string;
}

const initialState: IAuth = {
  user: userInfoObject,
  userInvitations: [],
  adminAction: '',
  loading: false,
  loadingText: ''
};

export interface IAdminInvitationAction {
  action: 'ACCEPTED' | 'REJECTED' | '';
  user: IUser | null;
  toUserId: number;
}

export const invitationAction = createAsyncThunk('admin/invitation-action', async (payload: IAdminInvitationAction) => {
  const response = await client<IResponse>({
    url: 'invitation-action',
    data: payload,
    method: 'POST'
  });
  return response.data;
});

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setInitialState: (state) => {
      state.adminAction = initialState.adminAction;
      state.loading = initialState.loading;
      state.loadingText = initialState.loadingText;
      state.user = initialState.user;
      state.userInvitations = initialState.userInvitations;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    addUserInvitation: (state, action: PayloadAction<IUser>) => {
      const isUserAlreadyHasInvitation = state.userInvitations.find(
        (userInvititaion) => userInvititaion.id === action.payload.id
      );
      if (!isUserAlreadyHasInvitation) {
        state.userInvitations = [...state.userInvitations, action.payload];
      }
    },
    removeUserFromInvitation: (state, action: PayloadAction<number>) => {
      state.userInvitations = state.userInvitations.filter((user) => user.id !== action.payload);
    },
    setAction: (state, action: PayloadAction<IAuth['adminAction']>) => {
      state.adminAction = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(invitationAction.pending, (state, action) => {
        state.loadingText = 'loading';
      })
      .addCase(invitationAction.fulfilled, (state, action) => {
        state.loadingText = 'succeeded';
        state.userInvitations = state.userInvitations.filter((user) => user.id !== action.payload.result.toUserId);
      })
      .addCase(invitationAction.rejected, (state, action) => {
        state.loadingText = 'failed';
      });
  }
});

export const { setUser, addUserInvitation, removeUserFromInvitation, setAction, setInitialState } = userSlice.actions;

export default userSlice.reducer;
