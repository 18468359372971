import React, { useContext, useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './loading-layer.scss';
import { client } from '../utils/client';

const updateTimeByTime = 2;

const LoadingLayer = (props: { content: any; userId: number | undefined }) => {
  const { content } = props;

  const [joinMeetingAgainTimer, setJoinMeetingAgainTimer] = useState(1);

  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const onJoinMeeting = () => {
    setIsBtnDisable(true);
    setJoinMeetingAgainTimer(updateTimeByTime * joinMeetingAgainTimer);
    client({
      url: 'join-meeting',
      method: 'POST',
      data: {
        userId: props.userId
      }
    });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setIsBtnDisable(false);
    }, joinMeetingAgainTimer * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [joinMeetingAgainTimer]);

  return (
    <div className="loading-layer">
      <LoadingOutlined style={{ fontSize: '86px', color: '#fff' }} />
      <p className="loading-text">{content}</p>
      {props.userId && (
        <button onClick={onJoinMeeting} disabled={isBtnDisable}>
          Join the meeting
        </button>
      )}
      {isBtnDisable && <p className="loading-text">Please Try again after {joinMeetingAgainTimer} sec.</p>}
    </div>
  );
};

export default LoadingLayer;
