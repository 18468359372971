import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import Home from './feature/home/home';
import Preview from './feature/preview/preview';
import Chat from './feature/chat/chat';
import Command from './feature/command/command';
import Subsession from './feature/subsession/subsession';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VideoInitMiddelware from './feature/video-init/video-init';
import TapSounds from './feature/video/components/tap-sounds';

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
    logClient: any | undefined;
  }
}

function App() {
  return (
    <div className="App">
      <ToastContainer limit={3} />
      <Router>
        <Switch>
          <Route path="/" component={Home} exact />
          {/* <Route
                path="/"
                render={(props) => <Home {...props} status={status} onLeaveOrJoinSession={onLeaveOrJoinSession} />}
                exact
              /> */}
          <Route path="/index.html" component={Home} exact />
          <Route path="/chat" component={Chat} />
          <Route path="/command" component={Command} />
          <Route path="/video" component={VideoInitMiddelware} />
          <Route path="/subsession" component={Subsession} />
          <Route path="/preview" component={Preview} />
          <Route path="/tapsounds" component={TapSounds} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
