import React, { useContext, useState } from 'react';
import { Button, Modal, List, Typography, Badge } from 'antd';
import './ParticipantButton.scss';
import { Participant } from '@zoom/videosdk';
import zoomContext from '../../../context/zoom-context';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from '../../../store/store';
import { IUser } from '../../../interfaces/IUser';
import { IAdminInvitationAction, invitationAction, removeUserFromInvitation } from '../../../store/slice/authSlice';
import { IoMdNotifications } from 'react-icons/io';

const ParticipantButton: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const userInvitationList = useAppSelector((state) => state.auth.userInvitations);
  const currentUser = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const showRequests = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleAccept = (id: number) => {
    const adminInvitationAction: IAdminInvitationAction = {
      action: 'ACCEPTED',
      user: currentUser,
      toUserId: id
    };
    dispatch(invitationAction(adminInvitationAction));
  };

  const handleReject = (id: number) => {
    const adminInvitationAction: IAdminInvitationAction = {
      action: 'REJECTED',
      user: currentUser,
      toUserId: id
    };
    dispatch(invitationAction(adminInvitationAction));
  };

  return (
    <>
      <Modal
        title="Participant Inbox"
        open={visible}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            Close
          </Button>
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={userInvitationList}
          renderItem={(participant: IUser) => (
            <List.Item
              className="request-item"
              actions={[
                <Button
                  key={`accept-${participant.id}`}
                  onClick={() => handleAccept(participant.id)}
                  className="accept-button"
                >
                  Accept
                </Button>,
                <Button
                  key={`reject-${participant.id}`}
                  onClick={() => handleReject(participant.id)}
                  className="reject-button"
                >
                  Reject
                </Button>
              ]}
            >
              <List.Item.Meta title={<Typography.Text strong>{participant.username}</Typography.Text>} />
            </List.Item>
          )}
        />
      </Modal>
      <div className="view-button-container">
        <Badge className="badge" count={userInvitationList.length} />
        <Button onClick={showRequests} className="view-button">
          <IoMdNotifications />
        </Button>
      </div>
    </>
  );
};

export default ParticipantButton;
