import React, { useContext, useState } from 'react';
import { PiSpeakerSimpleHighFill, PiSpeakerSimpleSlashFill } from 'react-icons/pi';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { UpOutlined } from '@ant-design/icons';
import { IconFont } from '../../../component/icon-font';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
import zoomContext from '../../../context/zoom-context';
import ZoomMediaContext from '../../../context/media-context';
const { Button: DropdownButton } = Dropdown;
const { Item: MenuItem } = Menu;
import './tap-sounds.scss';

const Speaker = () => {
  const [isSpeakerOn, setIsSpeakerOn] = useState(true);
  const { mediaStream } = useContext(ZoomMediaContext);
  const iconType = isSpeakerOn ? <PiSpeakerSimpleHighFill /> : <PiSpeakerSimpleSlashFill />;
  const onSpeakerClicked = () => {
    setIsSpeakerOn(!isSpeakerOn);
    if (isSpeakerOn) {
      mediaStream?.muteAllUserAudioLocally();
    } else {
      mediaStream?.unmuteAllUserAudioLocally();
    }
  };
  return (
    <div className="btn-1">
      <button className="main-btn" onClick={onSpeakerClicked}>
        {iconType}
      </button>
      <span>Speaker</span>
    </div>
  );
};

export { Speaker };
