import { getExploreName } from '../utils/platform';
import { isDev } from '../utils/util';

export const devConfig = {
  sdkKey: 'SJw6BKk-RA69aXtiDTHVuw',
  sdkSecret: '3rLhTj6mFFk84C1pZT5h0Md8b5SX5wdAhOwc',
  webEndpoint: 'zoom.us',
  topic: isDev() ? 'infinityplus1dev3' : 'infinityplus1',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: 'password',
  signature: '',
  sessionKey: isDev() ? 'infinityplus1dev3' : 'infinityplus1',
  userIdentity: '',
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1
};
