import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { BsFillRecordCircleFill, BsThreeDotsVertical, BsFillStopCircleFill } from 'react-icons/bs';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { PiSpeakerSimpleHighFill } from 'react-icons/pi';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './tap-sounds.scss';
import Slider from 'react-slick'; // Import Slider from react-slick
import { Participant } from '../../../index-types';
import { GrResume } from 'react-icons/gr';
import { GiSoundWaves } from 'react-icons/gi';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import {
  AudioChangeAction,
  ConnectionChangePayload,
  ConnectionState,
  DialoutState,
  MediaDevice,
  MutedSource,
  NetworkQuality,
  RecordingStatus,
  VideoCapturingState
} from '@zoom/videosdk';
import { IconFont } from '../../../component/icon-font';
import { AudioMutedOutlined, CheckOutlined, UpOutlined } from '@ant-design/icons';
import zoomContext from '../../../context/zoom-context';
import ZoomMediaContext from '../../../context/media-context';
import { useNetworkQuality } from '../hooks/useNetworkQuality';
import { useAppDispatch } from '../../../store/store';
import { useHistory } from 'react-router-dom';
import { setAction } from '../../../store/slice/authSlice';
import { toast } from 'react-toastify';
import { Speaker } from './speaker';
import { Mic } from './mic';
import micOn from './imgs/1m-on.png';
import micOf from './imgs/1m-off.png';
import { MdOutlineSpeakerPhone } from 'react-icons/md';
import { message } from 'antd';
import { isAndroidOrIOSBrowser } from '../../../utils/platform';
import { FaPause } from 'react-icons/fa';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
import DropdownButton from 'antd/lib/dropdown/dropdown-button';
import { getRecordingButtons, RecordButtonProps, RecordingButton } from './recording';
import { IoMdPeople } from 'react-icons/io';
import { useCurrentAudioLevel } from '../hooks/useCurrentAudioLevel';
import { ISpeakingUser, useSpeakingUsers } from '../hooks/useSpeakingUsers';

// Dummy data
const participants: Participant[] = [];

setTimeout(() => {
  participants.push(
    {
      audio: 'computer',
      bVideoOn: true,
      displayName: 'User 2',
      isAllowIndividualRecording: false,
      isHost: false,
      isManager: false,
      isVideoConnect: false,
      sharerOn: false,
      sharerPause: false,
      userId: 1
    },
    {
      audio: 'computer',
      bVideoOn: true,
      displayName: 'User 1',
      isAllowIndividualRecording: false,
      isHost: false,
      isManager: false,
      isVideoConnect: false,
      sharerOn: false,
      sharerPause: false,
      userId: 2
    },
    {
      audio: 'computer',
      bVideoOn: true,
      displayName: 'User 1',
      isAllowIndividualRecording: false,
      isHost: false,
      isManager: false,
      isVideoConnect: false,
      sharerOn: false,
      sharerPause: false,
      userId: 3
    }
  );
}, 2000);

const slickSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  adaptiveHeight: true,
  initialSlide: 0,
  draggable: false,
  rows: 1,
  slidesPerRow: 1,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const isAudioEnable = typeof AudioWorklet === 'function';

const networkQualityIcons = ['bad', 'bad', 'normal', 'good', 'good', 'good'];
interface TapToSpeakProps {
  participants: Participant[];
}
const TapToSpeakButton: React.FC<TapToSpeakProps> = (props: TapToSpeakProps) => {
  const [showOptions, setShowOptions] = useState<{ [key: number]: boolean }>({});
  const zmClient = useContext(zoomContext);
  const isHost = zmClient.isHost();
  const { mediaStream } = useContext(ZoomMediaContext);
  const recordingClient = zmClient.getRecordingClient();
  const [isStartedAudio, setIsStartedAudio] = useState(
    zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== ''
  );
  const [audio, setAudio] = useState(zmClient.getCurrentUserInfo()?.audio);
  const [isMuted, setIsMuted] = useState(!!zmClient.getCurrentUserInfo()?.muted);
  const [micList, setMicList] = useState<MediaDevice[]>(mediaStream?.getMicList() ?? []);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>(mediaStream?.getSpeakerList() ?? []);
  const [cameraList, setCameraList] = useState<MediaDevice[]>(mediaStream?.getCameraList() ?? []);
  const [activeMicrophone, setActiveMicrophone] = useState(mediaStream?.getActiveMicrophone());
  const [activeSpeaker, setActiveSpeaker] = useState(mediaStream?.getActiveSpeaker());
  const [activeCamera, setActiveCamera] = useState(mediaStream?.getActiveCamera());
  const [recordingStatus, setRecordingStatus] = useState<'' | RecordingStatus>(
    recordingClient?.getCloudRecordingStatus() || ''
  );
  const [recordingIsoStatus, setRecordingIsoStatus] = useState<'' | RecordingStatus>('');
  const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
  const [isStartedVideo, setIsStartedVideo] = useState(zmClient.getCurrentUserInfo()?.bVideoOn);
  const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);

  const recordingButtons: RecordButtonProps[] = getRecordingButtons(recordingStatus, isHost);

  const allNetworkQuality = useNetworkQuality(zmClient);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const currentUser = zmClient.getCurrentUserInfo();
  const { displayName, muted, bVideoOn, userId: participantId, isInFailover } = currentUser || {};

  const networkQuality = allNetworkQuality[participantId];
  const speakingUsers = useSpeakingUsers();

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const onLeaveClick = useCallback(async () => {
    await zmClient.leave();
    localStorage.clear();
    history.replace('/');
    dispatch(setAction(''));
    toast.success('You left the meeting.');
  }, [dispatch, history, zmClient]);

  const onEndClick = useCallback(async () => {
    await zmClient.leave(true);
    localStorage.clear();
    history.replace('/');
    dispatch(setAction(''));
    toast.success('You end the session successfully.');
  }, [dispatch, history, zmClient]);

  const handleToggleOptions = (sliderId: number) => {
    setShowOptions((prev) => ({
      ...prev,
      [sliderId]: !prev[sliderId]
    }));
  };

  const handleRemoveParticipant = (participantId: number) => {
    // Logic to remove participant
    console.log('i m clicked handleRemoveParticipant');
    if (participantId) {
      zmClient.removeUser(participantId);
    }
  };

  const handleMuteParticipant = async (participantId: number) => {
    if (participantId) {
      const isAudioMuted = mediaStream?.isAudioMuted(participantId);
      if (!isAudioMuted) {
        try {
          await mediaStream?.muteAudio(participantId);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('error', error);
        }
      }
    }
  };

  const onPassivelyStopShare = useCallback(({ reason }: any) => {
    console.log('passively stop reason:', reason);
  }, []);

  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      if (!isAndroidOrIOSBrowser()) {
        setCameraList(mediaStream.getCameraList());
      }
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);

  const onRecordingChange = useCallback(() => {
    setRecordingStatus(recordingClient?.getCloudRecordingStatus() || '');
  }, [recordingClient]);

  const onRecordingISOChange = useCallback(
    (payload: any) => {
      if (payload?.userId === zmClient.getSessionInfo().userId || payload?.status === RecordingStatus.Ask) {
        setRecordingIsoStatus(payload?.status);
      }
      console.log('recording-iso-change', payload);
    },
    [zmClient]
  );

  const onDialOutChange = useCallback((payload: any) => {
    setPhoneCallStatus(payload.code);
  }, []);

  const onVideoCaptureChange = useCallback((payload: any) => {
    if (payload.state === VideoCapturingState.Started) {
      setIsStartedVideo(true);
    } else {
      setIsStartedVideo(false);
    }
  }, []);

  const onShareAudioChange = useCallback(
    (payload: any) => {
      const { state } = payload;
      if (state === 'on') {
        if (!mediaStream?.isSupportMicrophoneAndShareAudioSimultaneously()) {
          setIsComputerAudioDisabled(true);
        }
      } else if (state === 'off') {
        setIsComputerAudioDisabled(false);
      }
    },
    [mediaStream]
  );

  const onHostAskToUnmute = useCallback((payload: any) => {
    const { reason } = payload;
    console.log(`Host ask to unmute the audio.`, reason);
  }, []);

  const onCaptionStatusChange = useCallback((payload: any) => {
    const { autoCaption } = payload;
    if (autoCaption) {
      message.info('Auto live transcription enabled!');
    }
  }, []);

  const onCanSeeMyScreen = useCallback(() => {
    message.info('Users can now see your screen', 1);
  }, []);

  const onConnectionChange = useCallback(
    (payload: ConnectionChangePayload) => {
      if (payload.state === ConnectionState.Closed || payload.state === ConnectionState.Fail) {
        localStorage.clear();
        history.replace('/');
        dispatch(setAction(''));
        toast.warning(payload.reason);
      }
    },
    [dispatch, history]
  );

  useEffect(() => {
    zmClient.on('passively-stop-share', onPassivelyStopShare);
    zmClient.on('device-change', onDeviceChange);
    zmClient.on('recording-change', onRecordingChange);
    zmClient.on('individual-recording-change', onRecordingISOChange);
    zmClient.on('dialout-state-change', onDialOutChange);
    zmClient.on('video-capturing-change', onVideoCaptureChange);
    zmClient.on('share-audio-change', onShareAudioChange);
    zmClient.on('host-ask-unmute-audio', onHostAskToUnmute);
    zmClient.on('caption-status', onCaptionStatusChange);
    zmClient.on('share-can-see-screen', onCanSeeMyScreen);
    zmClient.on('connection-change', onConnectionChange);
    return () => {
      zmClient.off('passively-stop-share', onPassivelyStopShare);
      zmClient.off('device-change', onDeviceChange);
      zmClient.off('recording-change', onRecordingChange);
      zmClient.off('individual-recording-change', onRecordingISOChange);
      zmClient.off('dialout-state-change', onDialOutChange);
      zmClient.off('video-capturing-change', onVideoCaptureChange);
      zmClient.off('share-audio-change', onShareAudioChange);
      zmClient.off('host-ask-unmute-audio', onHostAskToUnmute);
      zmClient.off('caption-status', onCaptionStatusChange);
      zmClient.off('share-can-see-screen', onCanSeeMyScreen);
      zmClient.off('connection-change', onConnectionChange);
    };
  }, [
    zmClient,
    onPassivelyStopShare,
    onDeviceChange,
    onRecordingChange,
    onDialOutChange,
    onVideoCaptureChange,
    onShareAudioChange,
    onHostAskToUnmute,
    onCaptionStatusChange,
    onCanSeeMyScreen,
    onRecordingISOChange,
    onConnectionChange
  ]);

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const toggleRecording = () => {
    console.log('props.participants', props.participants);
    // setIsRecording(!isRecording);
    // setIsPaused(false); // Reset pause state when recording state changes
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const menuItems = [];
  if (micList?.length && audio !== 'phone') {
    menuItems.push(
      getAntdItem(
        'Select a Microphone',
        'microphone',
        undefined,
        micList.map((i) =>
          getAntdItem(i.label, `microphone|${i.deviceId}`, activeMicrophone === i.deviceId && <CheckOutlined />)
        ),
        'group'
      )
    );
    menuItems.push(getAntdItem('', 'd1', undefined, undefined, 'divider'));
  }
  if (speakerList?.length && audio !== 'phone') {
    menuItems.push(
      getAntdItem(
        'Select a speaker',
        'speaker',
        undefined,
        speakerList.map((i) =>
          getAntdItem(i.label, `speaker|${i.deviceId}`, activeSpeaker === i.deviceId && <CheckOutlined />)
        ),
        'group'
      )
    );
    menuItems.push(getAntdItem('', 'd2', undefined, undefined, 'divider'));
  }
  menuItems.push(getAntdItem(audio === 'phone' ? 'Hang Up' : 'Leave Audio', 'leave audio'));

  if (zmClient.isHost()) {
    menuItems.push(getAntdItem('Mute All Users', 'muteAllUsers'));
  }

  const onMicrophoneMenuClick = async (key: string) => {
    if (mediaStream) {
      const [type, deviceId] = key.split('|');
      if (type === 'microphone') {
        if (deviceId !== activeMicrophone) {
          await mediaStream.switchMicrophone(deviceId);
          setActiveMicrophone(mediaStream.getActiveMicrophone());
        }
      } else if (type === 'speaker') {
        if (deviceId !== activeSpeaker) {
          await mediaStream.switchSpeaker(deviceId);
          setActiveSpeaker(mediaStream.getActiveSpeaker());
        }
      } else if (type === 'leave audio') {
        if (audio === 'computer') {
          await mediaStream.stopAudio();
        } else if (audio === 'phone') {
          await mediaStream.hangup();
          setPhoneCallStatus(undefined);
        }
      }
    }
  };

  const onMenuItemClick = (payload: { key: any }) => {
    onMicrophoneMenuClick(payload.key);
    if (payload.key === 'muteAllUsers') {
      const participants = zmClient.getAllUser();
      const currentUserId = zmClient.getCurrentUserInfo().userId;
      for (const participant of participants) {
        if (mediaStream && participant.userId !== currentUserId) {
          if (participant.audio) {
            mediaStream?.muteAudio(participant.userId);
          }
        }
      }
    }
  };

  const onRecordingClick = async (key: string) => {
    switch (key) {
      case 'Record': {
        await recordingClient?.startCloudRecording();
        break;
      }
      case 'Resume': {
        await recordingClient?.resumeCloudRecording();
        break;
      }
      case 'Stop': {
        await recordingClient?.stopCloudRecording();
        break;
      }
      case 'Pause': {
        await recordingClient?.pauseCloudRecording();
        break;
      }
      case 'Status': {
        break;
      }
      default: {
        await recordingClient?.startCloudRecording();
      }
    }
  };

  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
        // Clicked outside of the options menu, so close it
        setShowOptions({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="main">
      {(isHost || displayName === 'Nihal') && (
        <Drawer open={isOpen} size="50vw" onClose={toggleDrawer} direction="right" className="drawer participants">
          <div className="drawer_card">
            <div>
              <div>
                {props.participants.length > 0 &&
                  props.participants.map((participant: Participant) => {
                    const participantNetwork = allNetworkQuality[participant.userId]; // Declare participantNetwork here
                    return (
                      <div className="slider participant_slider" key={participant.userId}>
                        <div className="options-wrapper">
                          <div className="options-icon" onClick={() => handleToggleOptions(participant.userId)}>
                            <BsThreeDotsVertical />
                          </div>
                          <div
                            ref={optionsRef}
                            className={`options-menu ${showOptions[participant.userId] ? 'show' : ''}`}
                          >
                            <span onClick={() => handleRemoveParticipant(participant.userId)}>Remove Participant</span>
                            {!participant.muted && (
                              <span onClick={() => handleMuteParticipant(participant.userId)}>Mute Participant</span>
                            )}
                          </div>
                        </div>
                        <h3>{participant.displayName}</h3>
                        {(participant.bVideoOn ||
                          (participant.audio === 'computer' && participant.muted) ||
                          participant.isInFailover) && (
                          <div className="corner-name">
                            {participant.audio === 'computer' && participant.muted && (
                              <AudioMutedOutlined style={{ color: '#f00' }} />
                            )}
                            {participant.bVideoOn && participantNetwork !== undefined && (
                              <IconFont
                                type={`icon-network-${
                                  networkQualityIcons[
                                    Math.min(
                                      participantNetwork?.downlink ?? Number.MAX_VALUE,
                                      participantNetwork?.uplink ?? Number.MAX_VALUE
                                    )
                                  ]
                                }`}
                              />
                            )}
                            {participant.isInFailover && (
                              <IconFont
                                type="icon-reconnect"
                                style={{ color: '#FF9209', animation: 'loading 3s linear infinite' }}
                              />
                            )}
                            {/* {participant.bVideoOn && <span>{`${displayName}`}</span>} */}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </Drawer>
      )}

      <div className="user">
        <h1>{displayName}</h1>
      </div>
      <div className="tap-to-speak-container">
        <div className="tap-1">
          <Speaker />

          <div className="btn-1">
            <DropdownButton
              className="vc-dropdown-button audio-btn1"
              size="small"
              menu={getAntdDropdownMenu(menuItems, onMenuItemClick)}
              icon={<MdOutlineSpeakerPhone />}
              disabled={isComputerAudioDisabled}
            />
            <span>Audio</span>
          </div>

          <div className="btn-1">
            <button
              className="main-btn"
              onClick={() => {
                isHost ? onEndClick() : onLeaveClick();
              }}
              title={isHost ? 'End session' : 'Leave session'}
            >
              <AiOutlinePoweroff className="exit" />
            </button>
            <span>{isHost ? 'End session' : 'Leave session'}</span>
          </div>
        </div>
        <div className="tap">
          {isHost &&
            recordingButtons.map((button: RecordButtonProps) => {
              return (
                <RecordingButton
                  key={button.text}
                  onClick={() => {
                    onRecordingClick(button.text);
                  }}
                  {...button}
                />
              );
            })}
        </div>

        {isAudioEnable && <Mic />}
      </div>
      {(isHost || displayName === 'Nihal') && (
        <div className="participants slider-container">
          {speakingUsers.length > 0 && (
            <Slider {...slickSettings} className="sliders-main">
              {speakingUsers.map((participant: ISpeakingUser, index: number) => {
                if (index >= 3) return null;
                const participantNetwork = allNetworkQuality[participant.userId];
                return (
                  <div className="slider" key={participant.userId}>
                    <div className="options-wrapper">
                      {/* <div className="options-icon" onClick={() => handleToggleOptions(participant.userId)}>
                        <BsThreeDotsVertical />
                      </div> */}
                      <div className={`options-menu ${showOptions[participant.userId] ? 'show' : ''}`}>
                        <span onClick={() => handleRemoveParticipant(participant.userId)}>Remove Participant</span>
                      </div>
                    </div>
                    <h3>{participant.displayName}</h3>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      )}
      {/* end here slider */}

      {(isHost || displayName === 'Nihal') && (
        <div className="view-user-container">
          <button className="view-button" onClick={toggleDrawer}>
            <IoMdPeople />
          </button>
        </div>
      )}
    </div>
  );
};

export default TapToSpeakButton;
